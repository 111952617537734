import { Link } from "gatsby";
import LogoBanner from "./LogoBanner";
import MainNavbar from "./MainNavbar";
//import Alert from "react-bootstrap/Alert";

export default function Header() {
  return (
    <header>
      <div className="p-2 text-center">
        <Link to="/apply/">Apply here for entry in September 2024</Link> | 
	<Link to="/course_start/">Course Start 2024</Link>
      </div>
      <hr
        className="my-0 mx-auto"
        style={{
          maxWidth: "75rem",
          opacity: "8%",
        }}
      />
           

      <LogoBanner />
      <MainNavbar />
    </header>
  );
}
